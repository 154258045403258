global.env = {
 GATSBY_META_SITEURL: 'https://d24vdk1019kab6.cloudfront.net/',
 GATSBY_META_IMAGE: 'static/3f1f47940b4294a9aa64a3c1e010a451/7cf65/dictionary_banner_1200px.jpg',
 GATSBY_ELASTIC: 'http://10.1.0.11:9200',
 GATSBY_ELASTIC_INDEX: 'frac-dictionary',
 GATSBY_S3_BUCKET: 'igot-frac-dictionary',
 GATSBY_S3_HOSTNAME: 'igot-frac-dictionary.s3-website.ap-south-1.amazonaws.com',
 GATSBY_SITE_MAP: 'https://d24vdk1019kab6.cloudfront.net/sitemap.xml',
 GATSBY_ACCESS_ID: 'AKIAUT2CINKDYQD3TRVS',
 GATSBY_ACCESS_KEY: 'vnAy8VouBZBIr49SESqTEdcUBmNMEfr0xH8Zs8HE',
 GATSBY_DISTRIBUTION_ID: 'E1469ZNMUL8VRO',
 GATSBY_APOLLO_CLIENT: 'https://frac-dictionary-backend.igot-stage.in/graphql',
 GATSBY_META_DOMAIN: 'https://frac-dictionary.igot-stage.in/',
 GATSBY_BUCKET_PUSH: 'test',
};
